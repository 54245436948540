// import React from "react"
// import Layout from "../components/App/Layout"
// import Navbar from "../components/App/Navbar"
// import PageBanner from "../components/Common/PageBanner"
// import Footer from "../components/App/Footer"
// import ausimap from "../assets/images/about/graphic-australia-locations.svg"
// import WhyChoose from "../components/AboutUs/WhyChoose"
// import CoreValues from "../components/AboutUs/CoreValues"
// import Achievements from "../components/AboutUs/Achievements"
// import PathToOwnership from "../components/AboutUs/PathToOwnership"
// import CTABanner from "../components/Common/CTABanner"
// import SEO from "../components/App/SEO"

// const AboutUs = () => {
//   return (
//     <Layout>
//       <SEO
//         title="About Oxygen | Oxygen - Home Loans Made Simple"
//         description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
//       />
//       <Navbar />
//       <div className="about-header-area">
//         <div className="container">
//           <div className="row">
//             <div className="col">
//               <div className="about-header-wrapper">
//                 <PageBanner
//                   pageTitle="About Oxygen"
//                   pageSubTitle="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
//                 />
//                 <img src={ausimap} alt="austrlia map" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container">
//         <WhyChoose />
//         <CoreValues />
//         <Achievements />
//         <PathToOwnership />
//       </div>
//       <CTABanner />
//       <Footer />
//     </Layout>
//   )
// }

// export default AboutUs
import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ausimap from "../assets/images/about/graphic-australia-locations.svg"
import WhyChoose from "../components/AboutUs/WhyChoose"
import CoreValues from "../components/AboutUs/CoreValues"
import Achievements from "../components/AboutUs/Achievements"
import PathToOwnership from "../components/AboutUs/PathToOwnership"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"
import ImgAboutUs from "../assets/images/about_us.png"

const AboutUs = () => {
  return (
    <Layout>
      <SEO
        title="About Oxygen | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      <div className="about-header-area">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="about-header-wrapper">
                <PageBanner
                  pageTitle="About Oxygen"
                  pageSubTitle="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
                />
                <img src={ausimap} alt="austrlia map" />
              </div>
              <div>
                <WhyChoose />
              </div>
              <section className="imgaboutus">
                <div>
                  <img
                    src={ImgAboutUs}
                    className="img-fluid"
                    alt="Meet the Team"
                  />{" "}

                  <h5 className="text-center mt-3">Meet the Team</h5>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <CoreValues />
        {/* <Achievements /> */}
        <PathToOwnership />
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default AboutUs
